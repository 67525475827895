@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/layout';
@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/colors';

.base {
    padding: 0 !important;
    margin: auto 0 auto auto;
}

.qm {
    @extend .base;
}


.explore {
    @extend .base;
    font-size: 10px;
}

.avg {
    @extend .base;
}

.time {
    @extend .base;
    display: flex !important;
    margin: auto 0 auto spacing(1) !important;
    flex: 1 1 auto;
}

.name {
    margin: auto 0 !important;
}

.loader {
    margin: auto;
}

.info {
    display: flex;
    margin: auto 0 auto auto !important;
    flex: 1 1 auto;

    p:first-child {
        margin: auto auto auto 0;
    }

    p:last-child {
        margin: auto 0 auto auto;
    }
}

.root {
    margin-bottom: spacing(2);
}