@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/layout';
@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/colors';

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;

    padding: spacing(2) 0;
}

.buttom {
    &.isDisable {
        pointer-events: none;
        opacity: 0.5;
    }

    font-size: px-to-em(14);
    font-weight: bold;
}
