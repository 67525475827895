@import "node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/layout";
@import "node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/colors";

.content {
    padding-top: spacing(11, 5px);

    @include media-query(mobile) {
        padding-top: spacing(5);
    }

    color: $base-text-color-inverted;
}

.header {
    margin-bottom: spacing(2);
}

.activateButton {
    margin: spacing(2) 0px;
    width: 100%;
}

.loader {
    margin: auto;
    color: $color-white;
}

.message {
    margin-top: spacing(3, -4px);
    opacity: 0,8;
}
