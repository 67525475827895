@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/layout';
@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/colors';

.container {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.createButton {
    margin: spacing(2) 0px;
    width: 100%;
}

.message {
    margin-top: spacing(3, -4px);
    opacity: 0,8;
    font-size: 12px;
    font-style: italic;
    color: $color-dark-blue
}
